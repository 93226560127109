<script>
import { KEY_MAP } from "@/constants/key-map";
import { mapState } from "vuex";
import { isFunction } from "lodash";
const ORDER_MENU = {
  descending: "desc",
  desc: "desc",
  ascending: "asc",
  asc: "asc",
  null: undefined
};
const sortOrders = ["ascending", "descending"];
export default {
  name: "HtTable",
  componentName: "HtTable",
  props: {
    // 数据
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    // 表格列
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedRows: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否显示表格列设置
    showTableSetting: {
      type: Boolean,
      default: false
    },
    rowClassName: [Function, String],
    // 边框
    border: {
      type: Boolean,
      default: true
    },
    // 斑马纹
    stripe: {
      type: Boolean,
      default: true
    },
    // 是否要高亮当前行
    highlightCurrentRow: {
      type: Boolean,
      default: true
    },
    // 是否显示默认索引列
    isShowDefaultIndexColumn: {
      type: Boolean,
      default: true
    },
    // 是否显示默认选择列
    isShowDefaultSelectColumn: {
      type: Boolean,
      default: false
    },
    // 是否默认选择第一行
    isDefaultFirstRow: {
      type: Boolean,
      default: false
    },
    // 是否点击行选中当前行
    isRowClickSelect: {
      type: Boolean,
      default: true
    },
    // 是否当前行向上选择事件
    isKeyDownCurrentRowUpSelect: {
      type: Boolean,
      default: true
    },
    // 是否当前行向下选择事件
    isKeyDownCurrentRowDownSelect: {
      type: Boolean,
      default: true
    },
    // 分页
    pagination: {
      type: Boolean,
      default: false
    },
    // 表格合计
    showSummary: {
      type: Boolean,
      default: false
    },
    // 自动聚焦
    autofocus: {
      type: Boolean,
      default: false
    },
    // 自动高度
    autoHeight: {
      type: Boolean,
      default: false
    },
    // 表格名称
    tableName: {
      type: String,
      default: ""
    },
    // 表格ref
    tableRef: {
      type: String,
      default() {
        return "datatable";
      }
    },
    // 选择列模式
    selectionType: {
      type: String,
      default() {
        return "single" || "multiple";
      }
    },
    // 快捷键区域
    keyScope: {
      type: String,
      default() {
        return "table";
      }
    },
    // 定位当前行
    locationRowIndex: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 合计函数
    summaryMethod: Function,
    // 表格行是否可选择
    selectTableRow: {
      type: Function,
      default: () => {
        return true;
      }
    },
    // 格式化索引
    formatIndex: {
      type: Function
    }
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      baseSelected: [],
      defaultSort: {},
      cellStyle: {
        borderColor: "#E4E4E4"
      },
      headerCellStyle: {
        borderColor: "#E4E4E4"
      },
      curSelectionRow: [],
      currentRow: null,
      oldCurrentRow: {},
      isAllSelected: false,
      getPropValue: window.tool.getPropValue,
      scrollTop: 0,
      loadingType: ""
    };
  },
  watch: {
    data() {
      this.initData();
    },
    columns() {
      this.initColumns();
    },
    selectedRows: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.curSelectionRow = value.map(item => {
            item.selection = true;
            return item;
          });
        }
      }
    },
    isAllSelected(val) {
      this.$emit("allSelected", val);
    }
  },
  computed: {
    ...mapState(["table"]),
    // 选中行索引
    curSelectionRowIndex() {
      return this.curSelectionRow.map(item => item.rowIndex);
    },
    // 全选不确定状态
    indeterminate() {
      return this.curSelectionRow.length > 0 && this.selectedRows.length > 0 && !this.isAllSelected;
    },
    // 当前行是否高亮
    isHighlightCurrentRow() {
      return this.highlightCurrentRow && !!this.currentRow;
    },
    // 页条数
    pageSize() {
      return this.$attrs["page-size"] || this.table.pageSize;
    },
    // 表体表格
    bodyWrapper() {
      const table = this.$refs[this.tableRef];
      if (table && table.bodyWrapper) {
        return table.bodyWrapper;
      }
      return false;
    }
  },
  created() {
    this.initData();
    this.initColumns();
    this.setDefaultSort();
  },
  mounted() {
    this.bindHotkeys();
    // 监听表格的滑动
    this.tableScrollListener();
  },
  destroyed() {
    this.$hotkeys.deleteScope(this.keyScope);
  },
  activated() {
    // 设置滚动
    setTimeout(() => {
      this.autoTableScroll(this.scrollTop);
    }, 300);
  },
  methods: {
    // 监听表格的滑动
    tableScrollListener() {
      this.$nextTick(() => {
        const bodyWrapper = this.bodyWrapper;
        if (bodyWrapper) {
          bodyWrapper.addEventListener("scroll", this.tableScroll, true);
        }
      });
    },
    // 设置scrollTop
    tableScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
    // 绑定快捷键
    bindHotkeys() {
      this.$hotkeys(
        "*",
        this.keyScope,
        this.throttlingFn(e => {
          const { loading } = this.$attrs;
          if (!loading) {
            switch (e.keyCode) {
              case KEY_MAP.enter:
                // 当前行回车事件
                if (this.currentRow) {
                  e.preventDefault();
                  this.operationEvent(this.curSelectionRow);
                }
                break;
              case KEY_MAP.del:
                // 当前行删除事件
                if (this.currentRow) {
                  e.preventDefault();
                  this.deleteEvent(this.curSelectionRow);
                }
                break;
              case KEY_MAP.up:
                // 当前行向上选择事件
                if (!e.ctrlKey && !e.altKey) {
                  e.preventDefault();
                  this.currentRowUp();
                }
                break;
              case KEY_MAP.down:
                // 当前行向下选择事件
                if (!e.ctrlKey && !e.altKey) {
                  e.preventDefault();
                  this.currentRowDown();
                }
                break;
              case KEY_MAP.pageup:
                // 上一页
                this.goPre();
                break;
              case KEY_MAP.pagedown:
                // 下一页
                this.goNext();
                break;
              default:
            }
          }
        }, 300)
      );
      if (this.autofocus) {
        setTimeout(() => {
          this.setCurScope();
        }, 1000);
      }
    },
    currentRowUp() {
      if (this.currentRow) {
        // 当前表格有选中行 取表格可选择的上一行
        // 因向上找数据，及将当前行已上的数据截取后，再进行反向
        const tableData = JSON.parse(JSON.stringify(this.tableData)).splice(0, this.currentRow.rowIndex);
        const row = tableData.reverse().find(item => {
          return this.selectTableRow(item);
        });
        if (row) {
          // 有行数据时选择当前行，直接执行点击事件
          this.rowClick(row);
          this.autoTableScroll("up");
        } else {
          // 没有时执行上一页
          this.goPre();
        }
      } else {
        // 当前表格没有选中行 取表格可选择的第一行
        const row = this.tableData.find(item => this.selectTableRow(item));
        if (row) {
          // 有行数据时选择当前行，直接执行点击事件
          this.rowClick(row);
          this.autoTableScroll("up");
        } else {
          // 没有时执行上一页
          this.goPre();
        }
      }
    },
    currentRowDown() {
      if (this.currentRow) {
        // 当前表格有选中行 取表格可选择的下一行
        const row = this.tableData.find(item => {
          return item.rowIndex > this.currentRow.rowIndex && this.selectTableRow(item);
        });
        if (row) {
          // 有行数据时选择当前行，直接执行点击事件
          this.rowClick(row);
          this.autoTableScroll("down");
        } else {
          // 没有时执行下一页
          this.goNext();
        }
      } else {
        // 当前表格没有选中行 取表格可选择的第一行
        const row = this.tableData.find(item => this.selectTableRow(item));
        if (row) {
          // 有行数据时选择当前行，直接执行点击事件
          this.rowClick(row);
          this.autoTableScroll("down");
        } else {
          // 没有时执行下一页
          this.goNext();
        }
      }
    },
    // 向上 与 向下 表格滑动
    autoTableScroll(type) {
      setTimeout(() => {
        this.$nextTick(() => {
          const bodyWrapper = this.bodyWrapper;
          if (bodyWrapper) {
            const boxData = bodyWrapper.getBoundingClientRect();
            if (type === "up") {
              const curRowData = bodyWrapper.getElementsByClassName("base-current-row")[0].getBoundingClientRect();
              // 当前行的位置: top < 盒子的位置: top
              if (curRowData.top < boxData.top) {
                bodyWrapper.scrollTop = this.scrollTop - (boxData.top - curRowData.top);
              }
            } else if (type === "down") {
              const curRowData = bodyWrapper.getElementsByClassName("base-current-row")[0].getBoundingClientRect();
              // 当前行的位置: bottom > 盒子的位置: bottom
              if (curRowData.bottom > boxData.bottom) {
                bodyWrapper.scrollTop = this.scrollTop + (curRowData.bottom - boxData.bottom) + 10;
              }
            } else if (type === "top") {
              // 顶部
              const baseCurrentRow = document.getElementsByClassName("base-current-row");
              if (baseCurrentRow.length) {
                bodyWrapper.scrollTop = baseCurrentRow[0].offsetTop;
              } else {
                bodyWrapper.scrollTop = 0;
              }
            } else if (type === "bottom") {
              // 底部
              const baseCurrentRow = document.getElementsByClassName("base-current-row");
              if (baseCurrentRow.length) {
                bodyWrapper.scrollTop = baseCurrentRow[0].offsetTop;
              } else {
                bodyWrapper.scrollTop = 1000000;
              }
            } else {
              // 指定位置
              bodyWrapper.scrollTop = Number(type || 0);
            }
            this.loadingType = "";
          }
        });
      }, 0);
    },
    // 节流
    throttlingFn(fn, interval) {
      let startTime = 0;
      const that = this;
      return function() {
        const nowTime = new Date().getTime();
        const waitTime = interval - (nowTime - startTime);
        if (waitTime <= 0) {
          fn.apply(this, arguments);
          startTime = nowTime;
        }
        that.$emit("hotkeysUp", arguments[0], arguments[1]);
        if (
          [KEY_MAP.enter, KEY_MAP.del, KEY_MAP.up, KEY_MAP.down, KEY_MAP.pageup, KEY_MAP.pagedown].includes(
            arguments[0].keyCode
          )
        ) {
          event.preventDefault();
        }
      };
    },
    // 初始化数据
    initData() {
      let data = JSON.parse(JSON.stringify(this.data || []));
      data = data.map((row, i) => {
        // 自定义数据索引
        row.rowIndex = i;
        // 设置默认选择状态
        row.selection = row.selection || false;
        return row;
      });
      this.currentRow = null;
      this.curSelectionRow = data.filter(item => item.selection);
      this.tableData = JSON.parse(JSON.stringify(data));
      if (data.length) {
        if (this.loadingType === "goPre") {
          // 向上分页后焦点行处理
          for (const item of data.reverse()) {
            if (this.selectTableRow(item)) {
              this.rowClick(item);
              break;
            }
          }
          this.autoTableScroll("bottom");
        } else if (this.loadingType === "goNext") {
          // 向下分页后焦点行处理
          for (const item of data) {
            if (this.selectTableRow(item)) {
              this.rowClick(item);
              break;
            }
          }
          this.autoTableScroll("top");
        } else if (this.locationRowIndex) {
          // 定位当前行
          const item = data[this.locationRowIndex];
          if (item && this.selectTableRow(item)) {
            this.rowClick(item);
          }
        } else if (this.isDefaultFirstRow) {
          // 默认选择可选行的第一行
          for (const item of data) {
            if (this.selectTableRow(item)) {
              this.rowClick(item);
              break;
            }
          }
          this.autoTableScroll("top");
        }
      }
      setTimeout(() => {
        this.$emit("update:selectedRows", this.curSelectionRow);
        this.changeIsAllSelected();
        this.headerDragend();
      }, 0);
    },
    // 初始化列
    initColumns() {
      let columns = window.tool.deepCloneRecursive(this.columns || []);
      columns = columns.map(col => {
        if (col.attrs) {
          // eslint-disable-next-line no-prototype-builtins
          if (!col.attrs.hasOwnProperty("showOverflowTooltip")) {
            col.attrs.showOverflowTooltip = true;
          }
        } else {
          col.attrs = {
            showOverflowTooltip: true
          };
        }
        if (col.attrs.sortable) {
          col.attrs.sortOrders = sortOrders;
        }
        return col;
      });
      this.tableColumns = columns;
    },
    // 设置默认排序
    setDefaultSort() {
      for (const item of this.tableColumns) {
        if (item.attrs.sortable && item.attrs.order) {
          this.defaultSort = {
            order: item.attrs.order,
            prop: item.prop
          };
          break;
        }
      }
    },
    // 设置表格快捷键区域
    setCurScope() {
      this.$emit("keyScopeChange", this.keyScope);
      this.$hotkeys.setScope(this.keyScope);
    },
    // 表格行class
    tableRowClassName({ row, rowIndex }) {
      let defaultClass = "";
      if (this.rowClassName) {
        defaultClass = isFunction(this.rowClassName) ? this.rowClassName({ row, rowIndex }) : this.rowClassName;
      }
      // 选中的行
      if (this.currentRow && this.currentRow.rowIndex === row.rowIndex) {
        defaultClass += " base-current-row";
      }
      if (this.curSelectionRowIndex.includes(row.rowIndex)) {
        defaultClass += " selected-row";
      }
      return defaultClass;
    },
    // 排序
    sortChange(column) {
      const { prop, order } = column;
      this.$emit("sort-change", {
        sortField: this.getPropValue(prop),
        srcProp: prop,
        sort: ORDER_MENU[order]
      });
    },
    // 行点击
    rowClick(row, column, event, isUncheck) {
      const condition1 = event && event.type === "click" && !this.isRowClickSelect;
      const condition2 = !this.isKeyDownCurrentRowUpSelect;
      const condition3 = !this.isKeyDownCurrentRowDownSelect;
      if (condition1 || condition2 || condition3) {
        this.currentRow = row;
        this.curSelectionRow = [row];
        this.$emit("row-click", row, column, event);
        this.$emit("set-location-row-index", row.rowIndex);
        return;
      }

      // 当前行是否可选择
      if (this.selectTableRow(row)) {
        // 清空已经选择的列
        this.tableData = this.tableData.map(item => {
          item.selection = false;
          return item;
        });
        if (this.currentRow && this.currentRow.rowIndex === row.rowIndex && !isUncheck) {
          // 取消选中
          this.currentRow = null;
          this.curSelectionRow = [];
        } else {
          // 选中
          this.currentRow = row;
          this.curSelectionRow = [row];
          this.tableData[row.rowIndex].selection = true;
        }
        if (this.selectionType === "multiple") {
          this.changeIsAllSelected();
        }
        this.$emit("update:selectedRows", this.curSelectionRow);
        this.$emit("current-row-change", this.currentRow);
      }
      this.$emit("row-click", row, column, event);
      this.$emit("set-location-row-index", row.rowIndex);
    },
    // 行双击
    rowDBLClick(row) {
      this.operationEvent(row);
      this.rowClick(row, null, null, true);
    },
    // 表格列设置
    tableSetting() {
      this.$emit("table-setting");
    },
    // 选择行
    handleCheckboxChange(e, row, index) {
      if (this.selectionType === "multiple") {
        // 多选
        if (e) {
          // 选中当前行
          this.curSelectionRow.push(row);
        } else {
          // 取消当前行
          this.curSelectionRow = this.curSelectionRow.filter(item => item.rowIndex !== row.rowIndex);
        }
        this.changeIsAllSelected();
      } else {
        // 单选
        this.curSelectionRow = [];
        this.tableData = this.tableData.map(item => {
          item.selection = false;
          return item;
        });
        if (e) {
          this.curSelectionRow = [row];
          this.tableData[index].selection = true;
        }
      }
      this.currentRow = row;
      this.$emit("update:selectedRows", this.curSelectionRow);
      this.$emit("selection-change", this.curSelectionRow);
      this.$emit("current-row-change", this.currentRow);
      this.$emit("checkbox-change", e, row, index);
    },
    handleAllSelectedClick(e) {
      e.stopPropagation();
      if (e.target.tagName === "INPUT") return;
      setTimeout(() => {
        this.$emit("all-selection-click", this.isAllSelected);
      }, 0);
    },
    // 全选
    handleAllSelectedChange(e) {
      this.tableData = this.tableData.map((row, i) => {
        if (this.selectTableRow(row, i)) {
          // 设置可选行状态
          row.selection = e;
        }
        return row;
      });
      this.curSelectionRow = this.tableData.filter(item => item.selection);
      this.changeIsAllSelected();
      this.$emit("update:selectedRows", this.curSelectionRow);
      this.$emit("selection-change", this.curSelectionRow);
    },
    // 全选
    changeIsAllSelected() {
      const data = this.tableData.filter((row, i) => this.selectTableRow(row, i));
      this.isAllSelected = this.curSelectionRow.length === data.length;
    },
    // 处理多级字段
    getRowValue(row, prop) {
      if (Array.isArray(prop)) {
        let value = row;
        prop.forEach(key => {
          value = value && value[key];
        });
        return value;
      }
      return row[prop];
    },
    // 处理索引列
    generateIndex(row, columns, cellValue, index) {
      if (this.formatIndex) {
        return this.formatIndex(row, columns, cellValue, index);
      }
      const { "current-page": currentPage = 1 } = this.$attrs;
      return (currentPage - 1) * this.pageSize + index + 1;
    },
    // 列适配
    headerDragend() {
      // this.$refs[this.tableRef].doLayout();
    },
    // 分页
    pageChange(page) {
      this.$emit("pageChange", page);
    },
    // 打开当前列
    operationEvent(row) {
      this.$emit("rowOperation", row);
    },
    // 删除当前列
    deleteEvent(row) {
      this.$emit("rowDelete", row);
    },
    // 上一页
    goPre() {
      if (!this.pagination) {
        return;
      }
      this.loadingType = "goPre";
      const { "current-page": currentPage } = this.$attrs;
      if (currentPage > 1) {
        this.pageChange(currentPage - 1);
      } else {
        this.loadingType = "";
      }
    },
    // 下一页
    goNext() {
      if (!this.pagination) {
        return;
      }
      this.loadingType = "goNext";
      const { "current-page": currentPage, total } = this.$attrs;
      const totalPage = total / this.pageSize;
      if (totalPage > currentPage) {
        this.pageChange(currentPage + 1);
      } else {
        this.loadingType = "";
      }
    }
  },
  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      loading,
      // eslint-disable-next-line no-unused-vars
      selection,
      // eslint-disable-next-line no-unused-vars
      "pager-count": pagerCount,
      // eslint-disable-next-line no-unused-vars
      "current-page": currentPage,
      // eslint-disable-next-line no-unused-vars
      "pagination-layout": paginationLayout,
      "hide-on-single-page": hideOnSinglePage,
      total,
      ...attrs
    } = this.$attrs;

    const relAttrs = {
      ...attrs,
      height: this.autoHeight ? undefined : attrs.height || "100%"
    };

    // eslint-disable-next-line no-unused-vars
    const { "sort-change": sortChange, ...listeners } = this.$listeners;

    const renderSelectionHeader = () => {
      return this.selectionType === "multiple" ? (
        <el-checkbox
          v-model={this.isAllSelected}
          disabled={!this.tableData.length}
          indeterminate={this.indeterminate}
          nativeOn-click={e => this.handleAllSelectedClick(e)}
          on-change={e => this.handleAllSelectedChange(e)}
        />
      ) : (
        <span>选择</span>
      );
    };

    const renderSelectionBody = ({ row, $index }) => {
      return (
        <el-checkbox
          v-model={row.selection}
          disabled={!this.selectTableRow(row, $index)}
          nativeOn-click={e => e.stopPropagation()}
          on-change={e => this.handleCheckboxChange(e, row, $index)}
        />
      );
    };

    const renderHeaderDefault = label => {
      return <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{label}</span>;
    };

    const renderTableColumn = columns => {
      return columns.map(
        ({
          key,
          prop,
          label,
          width,
          formatter,
          render,
          renderHeader,
          classNames,
          align,
          children,
          type,
          attrs,
          listeners,
          fixed
        }) => {
          if (attrs) {
            // eslint-disable-next-line no-prototype-builtins
            if (!attrs.hasOwnProperty("showOverflowTooltip")) {
              attrs.showOverflowTooltip = true;
            }
          } else {
            attrs = {
              showOverflowTooltip: true
            };
          }

          if (attrs.sortable) {
            attrs.sortOrders = sortOrders;
          }

          if (type === "index") {
            return (
              <el-table-column
                label="序号"
                prop="index-number"
                min-width={width}
                fixed={fixed !== undefined ? fixed : true}
                align="center"
                attrs={attrs}
                on={listeners}
                label-class-name={this.showTableSetting ? "table-setting" : ""}
                formatter={(row, columns, cellValue, index) => this.generateIndex(row, columns, cellValue, index)}
                scopedSlots={{
                  header: this.showTableSetting
                    ? () => <i on-click={this.tableSetting} class="el-icon-setting"></i>
                    : () => <span>序号</span>
                }}
              />
            );
          } else if (type === "multiple") {
            return (
              <el-table-column
                label="选择"
                prop="selection"
                min-width={width}
                align="center"
                attrs={attrs}
                on={listeners}
                scopedSlots={{
                  default: renderSelectionBody,
                  header: renderSelectionHeader
                }}
              />
            );
          } else if (type === "expand") {
            return (
              <el-table-column
                label={label}
                prop={prop}
                min-width={width}
                align={align}
                type={type}
                attrs={attrs}
                on={listeners}
                scopedSlots={{
                  default: ({ row, $index }) => {
                    if (render) {
                      return render(this.getRowValue(row, prop), row, $index);
                    }
                  }
                }}
              />
            );
          } else if (type === "customMultiple") {
            return (
              <el-table-column
                label={label}
                key={key || this.getPropValue(prop)}
                prop={this.getPropValue(prop)}
                min-width={width}
                align={align}
                attrs={attrs}
                fixed={type === "controls"}
                on={listeners}
                scopedSlots={{
                  default: ({ row, $index }) => {
                    if (render) {
                      return render(this.getRowValue(row, prop), row, $index);
                    } else {
                      return (
                        <span class={classNames}>
                          {formatter
                            ? formatter(this.getRowValue(row, prop), row, $index)
                            : this.getRowValue(row, prop)}
                        </span>
                      );
                    }
                  },
                  header: () => {
                    return renderHeader();
                  }
                }}
              >
                {children && children.length ? renderTableColumn(children) : null}
              </el-table-column>
            );
          }
          return (
            <el-table-column
              label={label}
              key={key || this.getPropValue(prop)}
              prop={this.getPropValue(prop)}
              min-width={width}
              align={align}
              fixed={fixed}
              attrs={attrs}
              on={listeners}
              scopedSlots={{
                default: ({ row, $index }) => {
                  if (render) {
                    return render(this.getRowValue(row, prop), row, $index);
                  } else {
                    return (
                      <span class={classNames}>
                        {formatter ? formatter(this.getRowValue(row, prop), row, $index) : this.getRowValue(row, prop)}
                      </span>
                    );
                  }
                },
                header: () => {
                  return renderHeader ? renderHeader() : renderHeaderDefault(label);
                }
              }}
            >
              {children && children.length ? renderTableColumn(children) : null}
            </el-table-column>
          );
        }
      );
    };

    return (
      <div class="ht-table" on-click={this.setCurScope}>
        <el-table
          ref={this.tableRef}
          data={this.tableData}
          border={this.border}
          stripe={this.stripe}
          attrs={relAttrs}
          on={listeners}
          row-class-name={this.tableRowClassName}
          highlight-current-row={this.isHighlightCurrentRow}
          default-sort={this.defaultSort}
          show-summary={this.showSummary}
          summary-method={this.summaryMethod}
          current-row-key="rowIndex"
          on-sort-change={this.sortChange}
          on-row-click={this.rowClick}
          on-row-dblclick={this.rowDBLClick}
          on-header-dragend={this.headerDragend}
          cell-style={this.cellStyle}
          header-cell-style={this.headerCellStyle}
          v-loading={loading}
        >
          {this.isShowDefaultIndexColumn ? (
            <el-table-column
              prop="rowIndex"
              label="序号"
              align="center"
              fixed="left"
              width="40"
              label-class-name={this.showTableSetting ? "table-setting" : ""}
              scopedSlots={{
                header: this.showTableSetting
                  ? () => <i on-click={this.tableSetting} class="el-icon-setting"></i>
                  : () => <span>序号</span>
              }}
              formatter={(row, columns, cellValue, index) => this.generateIndex(row, columns, cellValue, index)}
            />
          ) : null}
          {this.isShowDefaultSelectColumn ? (
            <el-table-column
              prop="selection"
              label="选择"
              align="center"
              fixed="left"
              width="40"
              scopedSlots={{
                default: renderSelectionBody,
                header: renderSelectionHeader
              }}
            />
          ) : null}
          {this.$slots.default}
          {renderTableColumn(this.columns)}
        </el-table>
        {this.pagination && (
          <ht-pagination
            on={listeners}
            attrs={{
              currentPage: currentPage,
              total: total,
              pagerCount: pagerCount,
              paginationLayout: paginationLayout,
              hideOnSinglePage: hideOnSinglePage
            }}
            scopedSlots={{
              default: this.$scopedSlots.pagination
            }}
          />
        )}
      </div>
    );
  }
};
</script>
